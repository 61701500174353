<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Login form</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="form.username"
                    id="name"
                    name="login"
                    label="Login"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    v-model="form.password"
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" v-on:click="test">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "DefaultBar",
  data: function () {
    return {
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    test: function () {
      if (!this.form.username.length) return alert("Compilare lo username");
      if (!this.form.password.length) return alert("Compilare la password");

      let userIndex = this.$store.state.tempCollection.findIndex(
        (el) =>
          el.username == this.form.username && el.password == this.form.password
      );

      if (userIndex >= 0) {
        this.$store.state.myself = this.$store.state.tempCollection[userIndex];
        this.$store.state.logged = !this.$store.state.logged;
      } else alert("Credenziali non valide");

      // this.$store.state.myself.username = ;
    },
  },
};
</script>